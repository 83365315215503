html, body {
    height: 100%;
}

div#fullpagepreloader { 
    display: none;
    position: fixed; left: 0; top: 0; z-index: 999; width: 100%; height: 100%; overflow: visible;
    background-color: #34495e;
    opacity: 0.95;
    padding-top: 250px;

    h5 {
        color: #fff;
        font-weight: 400;
        font-variant: small-caps;
    }
}

nav {
    background-color:#34495e;
}

.dtp-buttons button:hover {
    background-color: #8BC34A;
}

td.tab-home {
    width: 20%;
    text-align: center;
}

span.info_nav{
    line-height:25px;
    display:inline-block;
    margin-left:185px;
    vertical-align:top;
}
div.graph{
    min-width: 310px;
    max-width: 93%;
    height: 400px;
    margin: 25px auto;
}
.experimentaldev{
    display:none;
}

/*
When creating your CSS selector,
change "brown" to something of your choosing
*/
.waves-effect.waves-main-color .waves-ripple {
    /* The alpha value allows the text and background color
    of the button to still show through. */
    background-color: #34495e;
}
/* label focus color */
.input-field input[type=text]:focus + label {
    color: #34495e;
}
/* label underline focus color */
.input-field input[type=text]:focus {
    border-bottom: 1px solid #34495e;
    box-shadow: 0 1px 0 0 #000;
}
/* icon prefix focus color */
.input-field .prefix.active {
    color: #34495e;
}

div.recherche-nav {
    margin-left:10px;
    margin-top:20px;
    display:inline-block;
}

#activity_feed {
    background-color: #222B30;
    padding: 0;
    box-shadow: rgba(0,0,0,0.5) 0px 2px 5px, inset rgba(0,0,0,0.2) 0px -1px 2px;
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    float:right;
    width: 25%;
    max-height: 100%;
    overflow: hidden;

    #app {
        overflow-y: auto;
        max-height: 68%;

        .saler_activity {
            padding: 15px 20px 20px 15px;
            font-size: 1.4em;
            color: #ccc;
            border-bottom: solid 1px #bbb;
            line-height: 1.5;

            .saler_activity_date {
                
            }
            /* color chip */
            .saler_activity_type {
                text-transform: uppercase;
                width: 100px;
                font-weight: bold;
                color: white;
                display: inline-block;
                /*font-size: 0.8em;*/
                padding: 2px 5px 2px 5px;
                margin-right: 5px;
                border-radius: 7px 7px 7px 7px / 7px 7px 7px 7px;
            }
            .order {
                background-color: #388e3c;
                width: 150px;
            }

            .visit {
                background-color: #7b1fa2;
                width: 110px;
            }

            .form {
                background-color: #6d4c41;
                width: 205px;
            }
        }
    }

    #global_stats {
        text-align:center;
        padding-top: 10px;
        border-bottom: solid 1px #bbb;

        .global_stats_date {
            color:#fff;
            font-size: 1.5em;
            opacity: 0.8;
            margin-bottom: 0.8em;
        }
        .stats_col_2 {
            font-weight:bold;
            color:#fff;
            float: left;
            width: 50.0000%;
            max-width: 600px;
            text-transform: uppercase;
        }
        .stats_col_3 {
            font-weight:bold;
            color:#fff;
            float: left;
            width: 33.0000%;
            max-width: 600px;
            text-transform: uppercase;
        }
        .stats_col_large {
            font-weight:bold;
            color:#fff;
            
            float: left;
            width: 100%;
            max-width: 800px;
        }
        .stats_label {
            font-size: 1.3em;
            text-transform: uppercase;
            opacity:0.7;
        }

        .stats_label_big {
            text-transform: uppercase;
            font-size: 1.3em;
            opacity:0.7;
        }

        .stats_value {
            font-size: 5em;
            opacity: 0.3;
        }
        #stats_nb_ca{
            font-size: 4.8em;
        }

    }
}

#infoCoordinates, #ua {
    position: absolute;
    left: 2px;
    bottom: 2px;
    border: none 1px black;
    background-color: transparent;
    z-index: 999;
    padding: 2px;
    font-size: 11px;
    color: #777;
}

.exportSalerCsvButton {
    margin: 5px;
    text-align: center;
}

.actions_links {
    a {
        &:link,&:hover,&:visited {
            color: black;
        }
    }
}

#progress_bar_customer {
    display: none;
}

span.info_nav span.module_title {
    margin: 20px 0 0 15px;
    font-size: 1.50rem;
    font-family: "Roboto", sans-serif;
}